.flagIdioma{
    max-width: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.containerFlag{
    display: flex;
    align-items: center;
    gap: 5px;
}

.SelectFlag{
    height: 100%;
}

.containerFlag span{
    color: #000;
}

.flagIdioma:hover{
    scale: 1.2;
    transition: .3s;
}

.flags-container{
    box-sizing: border-box;
    display: none;
    margin: 0 20px;
    top:28px;
    left: -20px;
    flex-direction: column;
    background-color: #ffffff;
    position: absolute;
    gap: 8px;
    justify-self: flex-end;
    width: 100%;
    border-radius: 0 0 15px 15px;
    padding: 10px 8px;
    border: 1px solid #8f8f8f;
}

.dropdown{
    box-sizing: border-box;
    background-color: #fff;
    width: 60px;
    position: relative;
    height: 30px;
    margin-left: 30px;
    cursor:pointer;
    border: 1px solid #8f8f8f;
    display: flex;
    align-items: center;
    padding: 3px 5px;
}

.dropdown span{
    color: #000;
    font-size: 14px;
}

.dropdown:after{
    content: "V";
    position: absolute;
    right: 8px;
    top: 3px;
    color: rgb(3, 3, 3);
}

.dropdown:hover .flags-container{
    display: flex;
}