.snackbar {
    background-color: #333;
    position: inherit;
    bottom: 0;
    align-items: center;
    /*
    left: 50%;
    margin-left: -200px;
    */
    padding: 8px;
    display: flex;
    width: 150px;
    justify-content: space-between;
    opacity: 0;
    visibility: hidden;
    color: #fff;
    border-radius: 4px;
    transition: all 200ms ease-in-out;
  }
  
  .snackbar.visible {
    opacity: 1;
    bottom: 20px;
    visibility: visible;
  }
  
  .snackbar.success {
    background-color: rgb(0, 158, 0);
  }
  
  .snackbar.info {
    background-color: rgb(86, 83, 255);
  }
  
  .snackbar.warning {
    background-color: rgb(255, 181, 44);
  }
  
  .snackbar.error {
    background-color: rgb(219, 55, 55);
  }
  
  .snackbar .close {
    font-size: 18px;
    cursor: pointer;
    transition: 200ms;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: #fff;
  }
  
  @media (max-width: 480px) {
    .snackbar {
      width: 100%;
      margin-left: 0;
      left: 0;
      border-radius: 4px 4px 0 0;
    }
  
    .snackbar.visible {
      bottom: 0;
    }
  }
  